<template>
  <header class="header">
    <div class="logotype">
      <div>powered by</div>
      <div class="header__logo">
        <logo-light v-if="mainStore.theme === 1" />
        <logo-dark v-else />
      </div>
    </div>
    <div v-if="!isHidden" class="language-switcher">
      <button-component
        v-for="(name, lang) in availableLanguages"
        :key="lang"
        :class="{ active: selectedLanguage === lang }"
        @click="changeLanguage(lang)"
      >
        {{ name }}
      </button-component>
    </div>
    <div class="mobile-button" v-if="isMobile && !isHidden">
      <button-component class="mobile-language-button" @click="toggleLanguage">
        {{ availableLanguages[selectedLanguage] }}
      </button-component>
    </div>
  </header>
</template>

<script setup>
import { useMainStore } from "@/stores/main";
import LogoDark from "@/components/svg/logo/LogoDark.vue";
import LogoLight from "@/components/svg/logo/LogoLight.vue";
import { useI18n } from "vue-i18n";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";

const mainStore = useMainStore();
const { locale } = useI18n();
const route = useRoute();

const availableLanguages = {
  en: "Eng",
  ru: "Рус",
  kz: "Қаз",
};

const selectedLanguage = ref(locale.value);

const isMobile = computed(() => {
  return window.innerWidth <= 768;
});

const isHidden = computed(() => {
  return route.name === "Integration Page";
});

const changeLanguage = (lang) => {
  if (lang in availableLanguages) {
    locale.value = lang;
    selectedLanguage.value = lang;
  }
};
const toggleLanguage = () => {
  const lang = Object.keys(availableLanguages);
  const currentIndex = lang.indexOf(selectedLanguage.value);
  const nextIndex = (currentIndex + 1) % lang.length;
  const nextLanguage = lang[nextIndex];
  changeLanguage(nextLanguage);
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #242136;
  padding-top: 15px;
}
.logotype {
  display: flex;
  justify-content: center;
  gap: 5px;
}
.header__logo {
  width: 150px !important;
}

.language-switcher {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: flex-end;
  position: absolute;
  margin-right: 20px;
  button {
    font-family: "Gilroy-Regular", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #4f5158;
    flex: none;
    order: 1;
    flex-grow: 0;
    padding: 4px 8px;

    border: none;
    background: none;
    cursor: pointer;
  }
  button.active {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
  }
}

.mobile-button {
  display: none;
}
@media screen and (max-width: 650px) {
  .header {
    gap: 20px;
  }

  .header__logo {
    width: 120px !important;
  }
  .language-switcher {
    display: none;
  }
  .mobile-button {
    display: flex;
    justify-content: flex-end;
    .mobile-language-button {
      display: flex;
      width: 50px;
      height: 30px;
      background: white;
      color: blue;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
