import { defineStore } from "pinia";

export const useMainStore = defineStore({
  id: "mainStore",
  state: () => ({
    session: "",
    flowSession: "",
    statuses: {},
    redirect_url: "",
    theme: null,
    proc_id: "",
    flow: [],
    flowType: "",
    failure_reason: "",
    countdown: {
      isTimerActive: true,
      timer: 60,
    },
    videoProcess: null,
  }),
  actions: {
    setSession(value) {
      this.session = value;
    },
    setRedirectUrl(value) {
      this.redirect_url = value;
    },
    setTheme(value) {
      this.theme = value;
    },
    setProcId(value) {
      this.proc_id = value;
    },
    setFlow(queue, flowType) {
      this.flow = queue;
      this.flowType = flowType;
    },
    disableTimer() {
      this.countdown.isTimerActive = false;
    },
    enableTimer() {
      this.countdown.isTimerActive = true;
    },
    decreaseCountdown() {
      this.countdown.timer--;
      if (this.countdown.timer > 0) {
        setTimeout(() => {
          this.decreaseCountdown();
        }, 1000);
      } else {
        this.disableTimer();
      }
    },
    resetCountdown() {
      this.countdown.timer = 60;
    },
  },
});
