import { createI18n as _createI18n } from "vue-i18n";
import en from "@/plugins/i18n/locales/en.json";
import ru from "@/plugins/i18n/locales/ru.json";
import kz from "@/plugins/i18n/locales/kz.json";

export const createI18n = () =>
  _createI18n({
    legacy: false,
    locale: "ru",
    allowComposition: true,
    fallbackLocale: "ru",
    messages: {
      en,
      ru,
      kz,
    },
  });

const i18n = createI18n();

export default i18n;
