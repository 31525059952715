import { checkStatus } from "@/plugins/axios/http/clients";
import { useMainStore } from "@/stores/main";
import router from "@/plugins/router";

export const setFlow = async (session) => {
  const mainStore = useMainStore();
  const statuses = (await checkStatus(session)).data;
  mainStore.statuses = statuses;
  let queue;

  if (statuses["in_system"] && statuses["has_cert"]) {
    statuses["is_default"]
      ? (queue = [
          { page: "Integration Page", components: [] },
          { page: "Password Page", components: [] },
        ])
      : (queue = [
          { page: "Document Page", components: [] },
          { page: "Integration Page", components: [] },
          { page: "Verification Page", component: [] },
          { page: "Password Page", components: [] },
        ]);
    mainStore.setFlow(queue, "short");
  } else {
    statuses["is_default"]
      ? (queue = [
          { page: "Sign Page", components: ["Document"] },
          { page: "Integration Page", components: [] },
          { page: "Sign Page", components: ["Signature"] },
          { page: "Create Page", components: [] },
        ])
      : (queue = [
          { page: "Sign Page", components: ["Document"] },
          { page: "Document Page", components: [] },
          { page: "Integration Page", components: [] },
          { page: "Verification Page", component: [] },
          { page: "Sign Page", components: ["Signature"] },
          { page: "Create Page", components: [] },
        ]);
    mainStore.setFlow(queue, "full");
  }
};

export const addReissueStep = () => {
  const mainStore = useMainStore();
  const newFlow = [
    { page: "Sign Page", components: ["Reissue"] },
    { page: "Reissue Page", components: [] },
  ];
  mainStore.setFlow(newFlow);
};

export const nextStep = async () => {
  const mainStore = useMainStore();
  mainStore.flow.shift();
  if (mainStore.flow.length > 0) {
    const nextStep = mainStore.flow[0].page;
    await router.push({ name: `${nextStep}` });
  } else {
    await router.push("/result?status=true");
  }
};

export const abortFlow = async (reason) => {
  await router.push(`/result?status=false&reason=${reason}`);
};

export const goToCurrentStep = async () => {
  const mainStore = useMainStore();
  const currentStep = mainStore.flow[0].page;
  await router.push({ name: `${currentStep}` });
};
