import { createRouter, createWebHistory } from "vue-router";
import { useMainStore } from "@/stores/main";
import { abortFlow } from "@/utils/flow";

const routes = [
  {
    path: "/",
    name: "Home Page",
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/demo",
    name: "Demo Page",
    component: () => import("@/views/DemoView.vue"),
  },
  {
    path: "/login",
    name: "Login Page",
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/sign",
    name: "Sign Page",
    component: () => import("@/views/SignView.vue"),
  },
  {
    path: "/check/:id",
    name: "Check Id Page",
    component: () => import("@/views/IdView.vue"),
  },
  {
    path: "/integration",
    name: "Integration Page",
    component: () => import("@/views/IntegrationView.vue"),
  },
  {
    path: "/check",
    name: "Check Page",
    component: () => import("@/views/CheckView.vue"),
  },
  {
    path: "/password",
    name: "Password Page",
    component: () => import("@/views/PasswordView.vue"),
  },
  {
    path: "/result",
    name: "Result Page",
    component: () => import("@/views/ResultView.vue"),
  },
  {
    path: "/create",
    name: "Create Page",
    component: () => import("@/views/CreateView.vue"),
  },
  {
    path: "/blockchain",
    name: "Blockchain Page",
    component: () => import("@/views/BlockchainView.vue"),
  },
  {
    path: "/agreement",
    name: "Agreement Page",
    component: () => import("@/views/AgreementView.vue"),
  },
  {
    path: "/reissue",
    name: "Reissue Page",
    component: () => import("@/views/ReissueView.vue"),
  },
  {
    path: "/not-found",
    name: "Not Found",
    component: () => import("@/views/NotFoundView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  const store = useMainStore();
  const ignoreList = [
    "Home Page",
    "Login Page",
    "Sign Page",
    "Result Page",
    "Demo Page",
    "Blockchain Page",
    "Check Page",
    "Check Id Page",
    "Not Found",
    "Agreement Page",
  ];
  const isFirstPage = ignoreList.includes(to.name);
  const isSessionExist = store.session.length > 0;

  if (!isFirstPage && !isSessionExist) {
    await abortFlow("refresh");
  }
});

export default router;
