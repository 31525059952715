<template>
  <toast-component />
  <header-component v-if="!isHome" />
  <router-view />
</template>

<script setup>
import { ref, watch } from "vue";
import HeaderComponent from "@/components/common/HeaderComponent.vue";
import router from "@/plugins/router";

const currentRoute = router.currentRoute;
const isHome = ref(currentRoute.value.path === "/");

watch(currentRoute, () => {
  isHome.value = currentRoute.value.path === "/";
});
</script>

<style scoped>
@import "@/assets/main.css";
</style>
