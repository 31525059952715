import { instance } from "@/plugins/axios";
export const checkStatus = async (sessionId) => {
  const data = {
    session_id: sessionId,
  };
  try {
    return await instance.post("/clients/status/", data);
  } catch (e) {
    return null;
  }
};
