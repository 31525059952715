import { createApp } from "vue";
import App from "./App.vue";
import PrimeVue from "primevue/config";
import pinia from "@/plugins/pinia";
import router from "@/plugins/router";
import i18n from "@/plugins/i18n";
import ToastService from "primevue/toastservice";
import InputMask from "primevue/inputmask";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import FileUpload from "primevue/fileupload";
import Toast from "primevue/toast";
import Checkbox from "primevue/checkbox";
import Password from "primevue/password";
import Calendar from "primevue/calendar";

import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

const app = createApp(App);
app.use(PrimeVue).use(pinia).use(router).use(i18n).use(ToastService);

app.component("ButtonComponent", Button);
app.component("InputText", InputText);
app.component("InputMask", InputMask);
app.component("FileUpload", FileUpload);
app.component("ToastComponent", Toast);
app.component("CheckboxComponent", Checkbox);
app.component("PasswordComponent", Password);
app.component("CalendarComponent", Calendar);
app.mount("#app");
